export const environment = {
  production: true,
  apiUrl: 'https://it-farmanager-qa.uc.r.appspot.com',
  firebaseConfig: {
	apiKey: "AIzaSyBi95hTcwV8N99DP1KkxbuiUNhv5uu0ybY",
	authDomain: "it-farmanager-qa.firebaseapp.com",
	projectId: "it-farmanager-qa",
	storageBucket: "it-farmanager-qa.appspot.com",
	messagingSenderId: "792845243945",
	appId: "1:792845243945:web:19334511b29612db932e38",
	measurementId: "G-2BMD870P4L"
  }
};
